
import React, {useState, useEffect} from 'react'; 
import {Nav, Bars, NavMenu, NavBtn, NavMenuLink} from '../components/Navbar/NavbarElement';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


import './Initial.css';

import Img1 from '../assets/img1.jpg';
import Img2 from '../assets/img2.jpg';
import Img3 from '../assets/img3.jpg';
import Img4 from '../assets/img4.jpg';
import Img5 from '../assets/img5.jpg';
import Img6 from '../assets/img6.jpg';

import Selo from '../assets/selo.png';


import Footer from '../components/footer';
import BtnDefault from '../components/btn-default';
import BtnDefault1 from '../components/btn-default1';
import BtnDefault2 from '../components/btn-default2';
import BtnDefault3 from '../components/btn-default3';
import SliderGallery from '../components/slidergallery';
import WrapperClients from '../components/wrapperclients';
import FormsContact from '../components/forms-contact';
import Gallery from '../components/gallery';
import Navbar from '../components/Navbar';
import SocialMedia from '../components/SocialMedia';

const images = [Img3, Img1, Img2, Img6, Img4, Img5];



function Initial() {
  const [currentSlide, setCurrentSlide] = useState(0);
  
 // Função para avançar o slide
  const nextSlide = () => {
    setCurrentSlide(currentSlide === images.length - 1 ? 0 : currentSlide + 1);
  };

  // Função para voltar um slide
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? images.length - 1 : currentSlide - 1);
  };
    useEffect(() => {
      const interval = setInterval(nextSlide, 5000); // Muda o slide a cada 5 segundos
      return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
    }, [currentSlide]);

  return (
    
    <>
   
    <div className="global">
    <div className="whatsappButton">
        <a href="https://api.whatsapp.com/send/?phone=5551996870966&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
    </div>
    <Navbar />
    <div className='blackline'></div>
    {/* <BackGroundMain /> 
    <NavItem icon={<AiOutlineMenu className='testunic'/>} >
      <DropdownMenu />
    </NavItem>*/} 
{/* 
    <div className='double page'>
      <div className="dbcontent">
      
        <div className="first-part">
          <div className="image-container">
           
            <img src={Selo} alt="selo movex 40 anos de experiencia" className='rotated-image'/>
          </div>
          <div className="text-container">
           
            <p>texto</p>
          </div>
        </div>

     
        <div className="second-part">
          <div className="image-container">
           
            <img src="caminho_para_sua_imagem" alt="Imagem" />
          </div>
          <div className="button-container">
          <BtnDefault/>
          </div>
        </div>
      </div>
    </div>*/}

    <div className='home page'>
        <div className="carousel">
      {images.map((image, index) => (
        <div
          key={index}
          className={index === currentSlide ? 'slide active' : 'slide'}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="overlay">
          <div className="overlay-layer"></div>
          <div className="overlay-content ">
            <div className="top-left-text"> 
            <img src={Selo} alt="selo movex 40 anos de experiencia" className='rotated-image'/>
            </div>
            <div className="center-right">
              <h2 className='text-title-section'>Eleve seu negócio<br />para o próximo nível</h2>
              <BtnDefault/>
            </div>
          </div>  
          </div>
        </div>
      ))}
      </div>

      {/* <button className="prev" onClick={prevSlide}>&#10094;</button>
      <button className="next" onClick={nextSlide}>&#10095;</button> */}
      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentSlide ? 'dot active' : 'dot'}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>

    </div>
      
    <div className='company page'>
        
        <div className='blackfaixa bfempr'>
            <h2 className='text-title-section'>
                Empresa
            </h2>
            <div className='btn-empresa-fo'>
              <NavMenuLink to="/Company">
                  {/* <BtnDefault/>*/}
              </NavMenuLink>
            </div>
        </div>

        <div className='company-container'>
          <div className='company-text'>
            <h3 className='empr-text'>
                A MOVEX é uma empresa de consultoria com mais
                 de 40 anos de experiência no mercado varejista. 
                 Nossa equipe é composta por profissionais altamente 
                 qualificados e dedicados a oferecer a melhor solução para o seu negócio
            </h3>
          </div>
          <div className='company-img'>
              <img src={Img1} alt="" className='company-img-div' /> 
          </div>
          <div className='company-text company-text-right'>
            <h3 className='empr-text'>
            Com a Movex, você pode contar com serviços personalizados 
            para o seu negócio, ajudando-o a se destacar no mercado e 
            aumentar suas vendas. Se você deseja melhorar a aparência 
            e a experiência de compra dos seus clientes, conte com a Movex para lhe ajudar.
            </h3>
          </div>
          <div className='company-btns'>
            <a 
                  href='https://api.whatsapp.com/send/?phone=5551996870966&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                  alt="" target='_blank' rel="noreferrer"
                  >
              <BtnDefault3 />
              <BtnDefault2 />
            </a>
          </div>
          
        </div>

       {/* 
        <div className='btns'>
          <a href='https://api.whatsapp.com/send/?phone=5551993926662&text=Ol%C3%A1%21+Visitei+o+site+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+3D%21&type=phone_number&app_absent=0' alt="" target='_blank' rel="noreferrer">
            <BtnDo />
            <BtnRender />
          </a>
        </div>
        */}
    </div> 
 
    <div className="portfolio page">

        <div className='blackfaixa bfport'>
            <h2 className='text-title-section'>
                Portfolio
            </h2>
            <div className='btn-portfolio-fo'>
              <NavMenuLink to="/Portfolio">
                <BtnDefault/> 
              </NavMenuLink>
            </div>
        </div>

        <div className='portfolio-container'>
        
          <div className="divteste">
          <Gallery/>
            
          </div>
        </div>
       
    </div>
    

      {/* 
    <div className="clientes page">
        <div className='blackfaixa bfclie'>
            <h3>
                Nossos clientes
            </h3>
            <h2 className='text-title-section'>
                Marcas que fazem parte<br></br> da nossa história
            </h2>
            <div className='btn-clientes-fo'>
              <NavMenuLink to="/Clients">
                  <BtnDefault/>
              </NavMenuLink>
            </div>
        </div>

        <div className='wrapperclients'>
          <WrapperClients />
        </div>
        
        <div className='clients-container'>
          <div className='clients-text'>
            <h3 className='contact-text'>
            Você também tem a oportunidade de se tornar um 
            cliente da nossa empresa e desfrutar de todos os 
            benefícios que oferecemos. Venha fazer parte da nossa 
            comunidade e transforme sua experiência conosco em uma 
            jornada eficaz, profissional e encantadora.
            </h3>
          </div>
          <div className='clients-btn'>
            <a 
                  href='https://api.whatsapp.com/send/?phone=5551999078668&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                  alt="" target='_blank' rel="noreferrer"
                  >
              <BtnDefault1 />
              <BtnDefault2 />
            </a>
          </div>
        </div>
    </div>
        */}
    <div className="contato page">
            <div className='blackfaixa bfcont'>
              <h2 className='text-title-section'>
                Conte conosco para elevar<br></br>seu negócio para o<br></br>próximo nível
              </h2>
              <h3>
                Entre em contato com a gente
              </h3>
            </div>

            <div className='contact-container'>
              <div className='contact-text'>
                <h3>Nossa equipe de suporte está disponível para ajudar 
                  com qualquer problema que você possa ter, e faremos o 
                  nosso melhor para garantir que sua experiência com nossa 
                  empresa seja a melhor possível. Venha fazer parte da nossa 
                  comunidade e transforme sua experiência conosco em uma jornada 
                  eficaz, profissional e encantadora.</h3>
              </div>
              <div className='contact-form'>
                <FormsContact />
              </div>
            </div>

            <div className='orangeline'></div>
            <SocialMedia />
    </div>
     

  

    <Footer />  
    
    </div>
  
    </>

);}

export default Initial;
