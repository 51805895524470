import './socialmedia.css';

import { BsInstagram } from "react-icons/bs";
import { ImWhatsapp } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";




function SocialMedia() {
    return(
        <div className='globalsocialmedia'>

            <div className="icons-space">

 
                <div className="content-icon">
                    <a href="https://www.instagram.com/movexinovacaocomercial/" target="_blank" className='socialmedialink' rel="noreferrer">
                        <BsInstagram className='icon-social'/>
                    </a>
                </div>
                <div className="content-text">
                    <a href='https://www.instagram.com/movexinovacaocomercial/' 
                        target="_blank" 
                        rel="noreferrer"> 
                        <h2>@movexinovacaocomercial</h2>
                    </a>
                </div>

                
                <div className="content-icon">
                    <a href="https://api.whatsapp.com/send?phone=555199907866&text=Ol%C3%A1!%20Visitei%20o%20site%20de%20voc%C3%AAs%20e%20estou%20interessado%20em%20or%C3%A7ar%20um%20projeto%20%F0%9F%98%80" target="_blank" className='socialmedialink' rel="noreferrer">
                        <ImWhatsapp className='icon-social'/>
                    </a>
                </div>
                <div className="content-text">
                    <a href='https://api.whatsapp.com/send?phone=5551996870966&text=Ol%C3%A1!%20Visitei%20o%20site%20de%20voc%C3%AAs%20e%20estou%20interessado%20em%20or%C3%A7ar%20um%20projeto%20%F0%9F%98%80'
                            alt=""
                            target='_blank'
                            rel="noreferrer">
                            <h2>+55 (51) 99687-0966</h2> 
                    </a> 
                </div>

                <div className="content-icon">
                    <HiOutlineMail className='icon-social'/>
                </div>
                <div className="content-text">
                    <h2>contato@movex.net.br</h2>
                </div>

            </div>

            
           

        </div>
    );
}

export default SocialMedia;