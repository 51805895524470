import './footer.css';

import { BsInstagram } from "react-icons/bs";
import { ImWhatsapp } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";

import logo from '../assets/movexlogobranco.png';
import vidaweblogo from '../assets/vidaweblogo.png';



function Footer() {
    return(
        <div className="globalfooter">
            
            <div className='developed'>
                <h3>Site criado e desenvolvido por</h3>
                <a href="https://www.instagram.com/sites_vidawebco/" alt="Instagram Vida Web sites por assinatura" target='_blank' rel="noreferrer" className='linkvw'>
                    <img src={vidaweblogo} alt='vida web logo site personalizado assinatura' />
                </a>
                <h3>VIDA WEB</h3>
            </div>

            <div className="iconsspace">
                <a href="https://www.instagram.com/movexinovacaocomercial/" target="_blank" rel="noreferrer"><BsInstagram className='icon'/></a>
                <a 
                href='https://api.whatsapp.com/send/?phone=5551996870966&text=Ol%C3%A1%21+Visitei+a+p%C3%A1gina+de+voc%C3%AAs+e+estou+interessado+em+or%C3%A7ar+um+projeto+para+meu+neg%C3%B3cio%21&type=phone_number&app_absent=0' 
                alt="" target='_blank' rel="noreferrer"
                ><ImWhatsapp className='icon'/></a>
            </div>

            <div className="auxfooter">
                <h1 className="legend">MOVEX: Personalizando espaços, <br></br>inspirando resultados.</h1>
                
                <div className='arealogo'>
                    <img src={logo} alt="teste" className='logofooter'/>
                </div>

                <h3 className="officialinfo">2025 © Movex Inovação Comercial - Todos os direitos reservados.
                <br></br>Movex Inovação Comercial - CNPJ 46.393.667/0001-00</h3>
            </div>

        </div>
    );
}

export default Footer;