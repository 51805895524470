import React, { useState } from 'react';

import './LandingPage.css';
import Navbar from '../components/Navbar';
import FormsContact from '../components/forms-contact';
import SocialMedia from '../components/SocialMedia';
import Footer from '../components/footer'
import BtnDefault from '../components/btn-default';
import Gallery from '../components/gallery';

function Contact() {

    return(
        <>
         <div className="global">
            <Navbar />
            <div className='blackline'></div>

            <div className="contato page unicroutecont">
            <div className='blackfaixa bfcont'>
              <h2 className='text-title-section'>
                Conte conosco para elevar<br></br>seu negócio para o<br></br>próximo nível
              </h2>
              <h3>
                Entre em contato com a gente
              </h3>
            </div>

            <div className='contact-container'>
              <div className='contact-text'>
                <h3>Nossa equipe de suporte está disponível para ajudar 
                  com qualquer problema que você possa ter, e faremos o 
                  nosso melhor para garantir que sua experiência com nossa 
                  empresa seja a melhor possível. Venha fazer parte da nossa 
                  comunidade e transforme sua experiência conosco em uma jornada 
                  eficaz, profissional e encantadora.</h3>
              </div>
              <div className='contact-form'>
                <FormsContact />
              </div>
            </div>

            <div className='orangeline'></div>
                <SocialMedia />
            </div>
            
            <div className="portfolio page">

              <div className='blackfaixa bfport'>
                  <h2 className='text-title-section'>
                      Portfolio
                  </h2>
                  <div className='btn-portfolio-fo'>
                     {/*  <BtnDefault/>*/}
                  </div>
              </div>

              <div className='portfolio-container'>
              <div className='divteste'>
            <Gallery />
            </div> 
              </div>

              <div className='orangeline'></div>
                <SocialMedia />
            </div>
           
            <Footer /> 
         </div>
        </>
    );
}

export default Contact;



