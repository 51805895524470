import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './LandingPage.css';
import Navbar from '../components/Navbar';
import Gallery from '../components/gallery';
import FormsContact from '../components/forms-contact';
import SocialMedia from '../components/SocialMedia';
import Footer from '../components/footer';
import BtnDefault from '../components/btn-default';

function Portfolio() {
  // Simulando um estado de carregamento
  const [loading, setLoading] = useState(true);

  // Simula o carregamento de dados
 /* useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 5000); // Carrega por 2 segundos
    return () => clearTimeout(timer);
  }, []);*/

  return (
    <>
      <div className="global">
      <Navbar />

        <div className="portfolio page unicrouteport">

          <div className='blackfaixa bfport'>
            <h2 className='text-title-section'>
              {/*<Skeleton width={600} enableAnimation={true} duration={5} baseColor="#e0e0e0" highlightColor="red"/> : */} Portfolio
            </h2>
            <div className='btn-portfolio-fo'>
            {/*  <Skeleton height={40} width={150} /> : <BtnDefault /> */}
            </div>
          </div>

          <div className='portfolio-container'>
            {/*  {loading ? <Skeleton height={400} /> :*/}
            <div className='divteste'>
            <Gallery />
            </div> 
          </div>
        
        </div>

        <div className="contato page">
          <div className='blackfaixa bfcont'>
            <h2 className='text-title-section'>
              {/* {loading ? <Skeleton width={300} /> : */}Conte conosco para elevar
              <br />
              {/* {loading ? <Skeleton width={300} /> :*/} seu negócio para o
              <br />
              {/* {loading ? <Skeleton width={300} /> : */}próximo nível
            </h2>
            <h3>
             {/*  {loading ? <Skeleton width={200} /> :*/} Entre em contato com a gente
            </h3>
          </div>

          <div className='contact-container'>
            <div className='contact-text'>
              {/* {loading ? (
                <>
                  <Skeleton count={5} />
                </>
              ) : ( */}
                <h3>Nossa equipe de suporte está disponível para ajudar 
                  com qualquer problema que você possa ter, e faremos o 
                  nosso melhor para garantir que sua experiência com nossa 
                  empresa seja a melhor possível. Venha fazer parte da nossa 
                  comunidade e transforme sua experiência conosco em uma jornada 
                  eficaz, profissional e encantadora.</h3>
              
            </div>
            <div className='contact-form'>
               <FormsContact />
            </div>
          </div>

          <div className='orangeline'></div>
           <SocialMedia />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Portfolio;
