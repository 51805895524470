import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import Img1 from '../assets/img1.jpg';
import Img2 from '../assets/img2.jpg';
import Img3 from '../assets/img3.jpg';
import Img4 from '../assets/img4.jpg';
import Img5 from '../assets/img5.jpg';
import Img6 from '../assets/img6.jpg';
import Img7 from '../assets/img7-sup-maxcenter.jpg';


/*import ImgVertical01 from '../assets/img1.webp';
import ImgVertical02 from '../assets/img2.webp';*/


import './gallery.css';


import {MdNavigateNext, MdClose} from 'react-icons/md';
import {RiArrowLeftSLine} from 'react-icons/ri';
import { IconContext } from 'react-icons';
 
const images = [
     { src: Img3, text: "VEJA MAIS - POSTO FRIDERICHS", link: "/postofriederichs" },
     { src: Img7, text: "VEJA MAIS - SUPER REDE MAX CENTER", link: "/superredemaxcenter" },

  /*Img3,
  Img7,
  Img3,
  Img5,
  Img6,
  Img2*/
]


const Gallery = () => {
    const [gutterSize, setGutterSize] = useState("600px");

    const [data, setData] = useState({img: '', i: 0})  

    const viewImage = (img, i)=>{
           setData({img, i})
    }

    const imgAction = (action) => {
        let i = data.i;
        if(action === 'next-img'){
            setData({img: images[i + 1], i: i + 1});
        }
        if(action === 'previous-img'){
            setData({img: images[i - 1], i: i - 1});
        }
        if(!action){
            setData({img: '', i: 0});
        }
    }

    useEffect(() => {
        const updateGutter = () => {
          if (window.innerWidth <= 768) {
            setGutterSize("50px"); // Gutter menor para dispositivos móveis
          } else {
            setGutterSize("600px"); // Gutter padrão para desktop
          }
        };
    
        updateGutter(); // Chama a função ao carregar a página
        window.addEventListener("resize", updateGutter); // Atualiza ao redimensionar a tela
    
        return () => window.removeEventListener("resize", updateGutter);
      }, []);
 
    return(
       
        <>
            {data.img &&
                <>
                    <div className='div-out'>
                        <img src={data.img} className='img-out' alt=''/>
                        <IconContext.Provider value={{ className: 'itprevious', color: 'white'}}>
                            <RiArrowLeftSLine 
                                onClick={() => imgAction('previous-img')}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'it' }}>
                            <MdClose onClick={() => imgAction()}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'itnext' }}>
                                <MdNavigateNext        
                                onClick={() => imgAction('next-img')}/>
                        </IconContext.Provider>
                    </div>
                </>
            }
            <div className='gallery-global'>
                <div className='inside-gallery'>
                    
                        <ResponsiveMasonry 
                            columnsCountBreakPoints={{350: 1, 750: 2, 900:3}}
                            className='responsive-masonry'
                           >
                            <Masonry gutter={gutterSize} className='masonry-columnn'>
                                {
                                    images.map((image, i) => (
                                       
                                <div key={i} className="img-gallery-container">
                                    <Link to={image.link} className="image-link">

                                    <img 
                                        src={image.src} 
                                        className="immg-responsive" 
                                        alt={image.text} 
                                        />
                                    
                                   <div className="goverlay">
                                        <span className="goverlay-text">{image.text}</span>
                                    </div> 
                                </Link>
                                </div>

                                    ))
                                }
                            </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </>
    );
}

export default Gallery;



